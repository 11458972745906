@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@400;500&family=Averia+Sans+Libre:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@400;500&family=Averia+Sans+Libre:wght@300;400&family=Tajawal&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

:root {
  --color: #09b6ac;
  --color2: #fcaf17;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Averia Sans Libre', cursive;

}

* {
  font-family: 'Source Sans Pro', sans-serif;
}



/* start navbar */
.navbarDash {
  height: 60px;
  background-color: var(--color);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}

header .btn-primary,
header .btn-primary:hover,
.btn:active,
.btn:focus {
  box-shadow: 0 0 0 0 transparent !important;
}

.navbarDash .imgClick {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 5px;
}

.navbarDash .logo {
  width: 100px;
}

.navbarDash .dropdown-toggle::after {
  display: none;
}

.logout {
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
}

/* end navbar */
/* start Content */
.content {
  position: relative;
  margin: 60px 0;
}

.row {
  padding: 0 !important;
  margin: 0 !important;
}

.content .sidbar {
  background-color: #f8f8f8 !important;
  position: fixed;
  padding: 2px 0px 50px;
  z-index: 2;
  overflow-y: auto;
  height: 100vh;
  box-shadow: 1px 1px 8px -6px #1a6bb4;
}

.content .cont {
  margin-left: 16.66666667%;
}

.content .sidbar ul {
  list-style: none;
  padding-left: 0;
}

.content .sidbar ul li {
  margin: 3px 0;
}

.content .sidbar ul li a {
  color: var(--color);
  text-decoration: none;
  font-size: 18px;
  width: 100%;
  display: inline-block;
  height: 100%;
  padding: 10px 15px;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  cursor: pointer;
}

.collapaSeeting {
  width: 100%;
  display: inline-block;
  height: 100%;
  padding: 10px 15px;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  cursor: pointer;
  color: var(--color);
  border: 0;
  font-size: 18px;
  text-align: left;
  background-color: transparent;
}


.content .sidbar ul li a:hover,
.collapaSeeting:hover {
  background-color: var(--color);
  color: #fff;
}

.content .sidbar ul li a i,
.collapaSeeting i {
  margin-right: 10px;
}


.content .sidbar .active {
  background-color: var(--color2);
  color: #fff;
}

@media(max-width:992px) {
  .content .cont {
    margin-left: 25%;
  }
}

@media(max-width:576px) {
  .collapsettShow {
    width: 100%;
    background-color: #f8f8f8;
    margin: 15px 10px;
    padding: 3px 5px;
    border-radius: 5px;
  }

  .content .sidbar ul li,
  .collapaSeeting {
    width: 51px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
  }

  .content .cont {
    margin-top: 55px;
    margin-left: 0;
  }

  .collapaSeeting i {
    margin-right: 0;
  }

  .content .sidbar {
    height: 60px;
    overflow: inherit;
  }

  .content .sidbar ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 5px;
    flex-wrap: wrap;
  }

  .content .sidbar ul li a i {
    margin-right: 0px;
  }

  .content .sidbar ul li a {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
}

@media(max-width:420px) {
  .content .sidbar {
    height: 120px;
  }

  .client,
  .user {
    margin: 75px 0 !important;
  }

  .cars {
    margin-top: 80px;
  }
}

/* end Content */

/* start login */
.login {
  background-color: var(--color);
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
}

.login .LoginCont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-color: #252525;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 70px 50px;
  width: 400px;
}

.login .LoginCont h3 {
  color: #fff;
  font-size: 35px;
  margin-bottom: 35px;
}

.login .LoginCont input {
  width: 100%;
  display: block;
  background-color: transparent;
  border: 2px solid var(--color);
  padding: 14px 40px;
  color: var(--color);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  text-align: center;
  outline: none;
  margin: 20px auto;
}

.form-check-input:checked {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
}

.form-check-input:focus {
  border-color: #00796b89 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #00796b39 !important;
}

.login .LoginCont button {
  width: 180px;
  display: block;
  margin: 20px auto;
  background-color: var(--color);
  border: 2px solid var(--color);
  padding: 14px 40px;
  color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  text-align: center;
  outline: none;
  font-size: 18px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.login .LoginCont button:hover {
  background-color: transparent;
  color: var(--color);
}

@media(max-width : 450px) {
  .login .LoginCont {
    width: 90%;
    padding: 70px 25px;
  }

}

/* end login */
/* start Client */
.client,
.user {
  margin: 20px 0;
}

.sc-dmRaPn {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  max-height: 300vh !important;
}

.sc-bcXHqe {
  border-radius: 5px !important;
  overflow: hidden;
  box-shadow: 1px 1px 8px -6px #1a6bb4;
}

.sc-iveFHk {
  color: var(--color) !important;
}

.fwKvpK {
  color: var(--color) !important;
  font-size: 20px;
}

.sc-bjUoiL {
  color: var(--color);
}

.sc-dIouRR {
  margin-bottom: 20px;
  padding: 4px 0px 4px 0px !important;
}

.form-control:focus {
  color: var(--color) !important;
  background-color: #fff;
  border-color: var(--color) !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #00796b33 !important;
}

.sc-fEXmlR {
  padding: 6px !important;
  border-radius: 5px;
  margin-bottom: 1px;
  min-height: 20px !important;
}

.sc-dIfARi {
  padding: 0 !important;
  margin-bottom: 10px;
}

.sc-fLcnxK {
  border-radius: 5px;
}

.sc-iJnaPW {
  justify-content: center !important;
  box-shadow: 1px 1px 8px -6px #1a6bb4;
  border-radius: 0 0 5px 5px;
  min-height: 47px !important;
}

.sc-hLBbgP:last-of-type {
  width: 500px !important;
  min-width: 300px !important;
}

.sc-bjfHbI {
  color: var(--color2);
}

.rdt_TableHeadRow {
  background-color: var(--color2) !important;
  color: #fff;
  font-size: 16px;
  min-height: 45px !important;
}

.sc-hHTYSt>div {
  min-height: 45px !important;
}

.sc-jqUVSM {
  padding: 0px 10px;
}

.sc-crXcEl {
  justify-content: center !important;
}

.rdt_TableCell {
  color: #8a9b9a !important;
  font-size: 15px !important;
}

.rdt_TableCell img {
  width: 60px;
  height: 60px;
  padding: 3px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.btn-primary {
  background-color: var(--color) !important;
  color: #fff !important;
  border-radius: 18px !important;
  -webkit-border-radius: 18px !important;
  -moz-border-radius: 18px !important;
  -ms-border-radius: 18px !important;
  -o-border-radius: 18px !important;
  border: 1px solid var(--color) !important;
  padding: 2px 8px !important;
  margin-right: 3px !important;
  min-width: 75px;
  font-size: 15px !important;
}

a.btn-primary {
  background-color: #e2aa3d !important;
  border: 1px solid var(--color2) !important;
}

.sc-hKMtZM:last-of-type {
  min-width: 220px !important;
}

.sc-iIPllB {
  justify-content: center !important;
}

.sc-bZkfAO,
.sc-cxabCf,
.sc-bZkfAO {
  font-size: 16px;
  outline: 0 !important;
}

.modal {
  padding-left: 0 !important;
}

.modalAddUser .modal-dialog {
  max-width: 60% !important;
}

@media(max-width:600px) {
  .modalAddUser .modal-dialog {
    margin: auto;
    max-width: 85% !important;
  }
}

/* end Client */
/* start cars */
/* .cars {
  margin-top: 80px;
} */



/* end cars */
/* start Slider  */
.PagSlider {
  /* text-align: center; */
  margin: 18px 0;
}

.PagSlider .cartPag {
  box-shadow: 1px 1px 8px -6px #1a6bb4;
  padding: 20px;
  margin: 10px 0;
}

.PagSlider .cartPag img {
  width: 100%;
  height: 210px;
  margin-bottom: 20px;
}

.PagSlider .cartPag h4 {
  color: var(--color2);
  font-size: 25px;
  text-transform: capitalize;
}

.PagSlider .cartPag h5 {
  text-align: center;
}

.PagSlider .cartPag h3 {
  text-transform: capitalize;
  color: var(--color);
  font-size: 25px;
}

.PagSlider .cartPag .Ar,
.Ar {
  text-align: right;
  font-family: 'Tajawal', sans-serif;
}

.PagSlider .cartPag h5 i {
  font-size: 45px;
  color: var(--color2);
  margin-bottom: 20px;
}

.PagSlider .cartPag p {
  color: #888;
  text-transform: capitalize;
}

.PagSlider .cartPag h6 {
  font-size: 17px;
  color: var(--color);
  margin-bottom: 15px;
}

.PagSlider .cartPag span {
  color: #888;
}

.PagSlider .cartPag button {
  width: 46%;
}

/* end Slider  */

/* start Profile */
.formUser {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.formUser>div {
  display: flex;
  align-items: center;
}

.formUser img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 15px;
  border: 3px solid #fff;
  padding: 5px;
}

.formUser button {
  width: 150px;
  border: 1px solid var(--color);
  background-color: var(--color);
  color: #fff;
  margin: 13px;
  padding: 3px;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.dataProfile {
  background-color: #f8f8f8;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dataProfilHead {
  background-color: var(--color);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataProfilHead h6 {
  text-transform: capitalize;
  color: #fff;
}

.dataProfileImage h6 {
  color: var(--color);
  margin: 20px 0;
}

.dataProfileImage {
  width: 100%;
  text-align: center;
  margin-top: -40px;
}

.dataProfileFoot {
  margin: 58px 20px 40px;
  display: flex;
  justify-content: space-around;
}

.dataProfileFoot span {
  display: block;
  text-align: center;
}

.dataProfileFoot p {
  color: var(--color);
}

.dataProfileFoot button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: var(--color);
}

.dataProfileImage img {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  padding: 5px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* end Profile */
/* start TermsAndCondition */
.Terms {
  /* background-color: #f7f7f7; */
}

/* end TermsAndCondition */
/* global */
.modal.show .modal-dialog {
  top: 15% !important;
}

.modal {
  z-index: 999999999999 !important;
}

.LoadParent {
  position: relative;
  width: 100%;
  height: calc(100vh - 180px);
}

.loading {
  position: absolute;
  top: 60%;
  left: 50%;
  width: 100px;
  transform: translate(-25%, -50%);
  -webkit-transform: translate(-25%, -50%);
  -moz-transform: translate(-25%, -50%);
  -ms-transform: translate(-25%, -50%);
  -o-transform: translate(-25%, -50%);
}

.loading span {
  display: block;
  position: absolute;
  bottom: 0;
  width: 9px;
  height: 5px;
  background-color: var(--color);
  animation: moving 1.5s infinite ease-in-out
    /*اسم الانيميشنو-عدد الثوانى-عدد مرات التكرار*/
}

.loading span:nth-of-type(2) {
  left: 11px;
  animation-delay: .2s
    /*عشان أأخر الانيمسشن ثانيتين*/
}

.loading span:nth-of-type(3) {
  left: 22px;
  animation-delay: .4s
}

.loading span:nth-of-type(4) {
  left: 33px;
  animation-delay: .6s
}

.loading span:nth-of-type(5) {
  left: 44px;
  animation-delay: .8s
}

@keyframes moving {
  0% {
    height: 5px;
    transform: translatey(0);
    background-color: #00796B
  }

  25% {
    height: 30px;
    transform: translatey(15px);
    background-color: #00796bbd
  }

  50% {
    height: 5px;
    transform: translatey(0);
    background-color: #00796b53
  }

  100% {
    height: 5px;
    transform: translatey(0);
    background-color: #00796B
  }
}

/* start chart */
.home {
  position: relative;
  z-index: 1;
}

.home h4 {
  color: var(--color2);
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 12px;
}

.apexcharts-menu-item {
  text-align: left;
}

#chart {
  box-shadow: 1px 1px 8px -6px #1a6bb4;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

.chartdriver {
  position: relative;
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-selected,
.apexcharts-pan-icon {
  display: none;
}

.css-1lwbda4-MuiStack-root {
  margin-bottom: 40px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root * {
  color: var(--color) !important;
  /* background-color: var(--color) !important; */
  border-color: var(--color) !important;
}

.css-3eghsz-PrivatePickersYear-button.Mui-selected {
  background-color: var(--color) !important;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  left: 2% !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--color) !important;
}

/* end chart */